<template>
  <svg
    width="84px"
    height="84px"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      transform="translate(24.000000, 31.000000)"
      stroke="currentColor"
      stroke-linecap="square"
      stroke-width="5"
    >
      <line x1="0" y1="0.5" x2="36" y2="0.5" id="Line-3"></line>
      <line x1="0" y1="11.5" x2="36" y2="11.5" id="Line-3-Copy"></line>
      <line x1="0" y1="22.5" x2="36" y2="22.5" id="Line-3-Copy-2"></line>
    </g>
  </svg>
</template>
