import { ref } from "vue";

const isSidebarOpen = ref<boolean>(false);

export default (): any => {
  const setSidebar = (state: boolean): void => {
    isSidebarOpen.value = state;
  };

  const closeSidebar = (): void => setSidebar(false);
  const toggleSidebar = (): void => setSidebar(!isSidebarOpen.value);

  return {
    isSidebarOpen,
    toggleSidebar,
    setSidebar,
    closeSidebar,
  };
};
